<template>
  <v-app :class="(($route.name == 'Job')?'job-page':'')">
    <div class="feautured-image">

      <template v-if="$store.state.job !== false" >
     
 <img :src="$store.state.job.image" class="header-img">
</template>
<template v-else>
   <img src="@/assets/images/1.jpg" class="header-img">
     <p class="header-title">
          <span>ویزالند</span>
 منتظر شماست
        </p>

</template>


       
        <h2 v-if="$store.state.job !== false">{{$store.state.job.title}}</h2>
        <ul v-if="$store.state.job !== false">
<li>
  <span class="icon ">
    <img   :src="require('./assets/people.svg')">
  </span>
  {{$store.state.job.sex}}
</li>
<li>
  <span class="icon location">
        <img   :src="require('./assets/location.svg')">
  </span>
  {{$store.state.job.city}}
</li>
        </ul>
    </div>
      
<Header />

    <v-main >
   
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
  import Header from './components/Header'
 
export default {
  name: 'App',
  components: {
      Header,
    },
         created() {

const _this = this

 let senddata = {  data: {  data: ['select', 'departments', '*'] }, actions: ['get'] };
 this.$http.post(_this.$store.state.url,{state:JSON.stringify( senddata )}).then((response) => {
   _this.$store.state.departments = response.data;
      })


  senddata = {  data: {  data: ['select', 'tags', '*'] }, actions: ['get'] };
 this.$http.post(_this.$store.state.url,{state:JSON.stringify( senddata )}).then((response) => {
   _this.$store.state.tags = response.data;
      })


  senddata = {  data: {  data: ['select', 'jobs', '*',{active:1}] }, actions: ['get'] };
 this.$http.post(_this.$store.state.url,{state:JSON.stringify( senddata )}).then((response) => {


   response.data.forEach((element, index) => {
 
     response.data[index].tags =  response.data[index].tags.split(",")
   })


   
   _this.$store.state.jobs = response.data;


  
      })


         },
     mounted: function () {



  // this.$on('jobs', function(){
  //   alert('sdsdds')
  //       })





     },
  data: () => ({
    //
  }),
};
</script>
<style>
  /* @import './assets/style.css'; */
</style>