import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  state: {
    url : 'https://careers.visaland.org/api/function.php',
    jobs:[],
    tags:[],
    departments:[],
    job: false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
